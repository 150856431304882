import Vue from "vue";
import App from "./App.vue";
import VueResource from "vue-resource";
import vuetify from "@/plugins/vuetify";
import router from "./router";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);
Vue.use(VueResource);

import '@/assets/styles.css'
import '@/assets/confirm.css'
import confirm from '@/plugins/confirm'
Vue.use(confirm)

if ( window.location.origin == 'http://localhost:8080' || window.location.origin == 'http://localhost:8084' ) {
  Vue.http.options.root = "http://localhost:3000/";
} else {
  Vue.http.options.root = "https://prof.firpo.ru/";
}
Vue.config.productionTip = false;

import Auth from '@/plugins/auth.js'
Vue.use(Auth)

router.beforeEach(
  (to, from, next) => {
    if ( to.matched.some(record => record.meta.forVisitors) ) {
      if ( Vue.auth.isAuthenticated() ) {
        next({
          path : '/' // Доступна только незавторизованным пользователям
        })
      } else next()
    }
    else if ( to.matched.some(record => record.meta.forAuth) ) {
      if ( !Vue.auth.isAuthenticated() ) {
        next({
          path : '/login' // Доступна только авторизованным пользователям
        })
      } else next()
    }
    else next()
  }
)

Vue.http.interceptors.push((request, next) => {
	if ( !request.headers.has('Authorization') && Vue.auth.getToken() ) {
		request.headers.set('Authorization', Vue.auth.getAuthHeader());
	}
	next((response) => {
		if(response.status == 401 && router.history.current.name != 'LoginPage') {
      Vue.$toast.open({ 
				message : 'Вы не авторизованы',
				type: "error",
				duration: 5000, 
				dismissible: true
			});
			Vue.auth.destroyToken()
			router.push({"name":"LoginPage"})
    }
	})
})

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
