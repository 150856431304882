export default function (Vue) {
    Vue.auth = {
		
		setToken (access_token) {
			localStorage.setItem('access_token', access_token)
		},
  
		getToken (){
			var access_token = localStorage.getItem('access_token')

			if ( !access_token )
				return null
			
			return access_token
		},

		getAuthHeader() {
			return 'Bearer ' + this.getToken()
		},
  
		destroyToken(){
			localStorage.removeItem('access_token')
		},

		isAuthenticated (){
			if ( this.getToken() )
				return true;
			else
				return false;
		}
	}
  
	Object.defineProperties(Vue.prototype, {
		$auth: {
			get: function(){
				return Vue.auth
			}
		}
	})
}
