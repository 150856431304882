var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[(!(_vm.cabinet_status.id > 0))?_c('div',[_c('div',{staticClass:"s--plan"},[_c('h3',{staticClass:"d-flex justify-space-between",staticStyle:{"color":"rgb(40,101,225)"}},[_c('span',[_vm._v("Достижение персонального плана рассмотрения заявок:")]),_c('span',[_vm._v("всего заявок "+_vm._s(_vm.total)+"; персональный план "+_vm._s(_vm.params.plan))])]),_c('v-progress-linear',{staticClass:"mt-2",attrs:{"buffer-value":_vm.params.factOpen,"stream":"","rounded":"","value":_vm.params.factClose,"height":"20","dark":(_vm.params.factClose > 50 ? true : false),"color":"primary"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}],null,false,3002676263)})],1),_c('v-checkbox',{attrs:{"dense":"","hide-details":"","label":"Показать только заявки в работе"},model:{value:(_vm.isWork),callback:function ($$v) {_vm.isWork=$$v},expression:"isWork"}}),_c('div',{staticClass:"s--table"},_vm._l((_vm.references),function(item,i){return _c('div',{key:i,staticClass:"card-row"},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-row',{staticClass:"small-bottom-offset"},[_c('v-col',{attrs:{"lg":"8","cols":"12"}},[_c('div',{staticClass:"s--row-overlay"},[_vm._v(" "+_vm._s(item.ter_name)+" "),(item.isConnectivity > 0)?_c('v-divider'):_vm._e(),(item.isConnectivity > 0)?_c('v-chip',{attrs:{"label":"","color":"lime lighten-4"}},[_vm._v(" к рассмотрению ")]):_vm._e()],1)]),_c('v-col',{attrs:{"lg":"2","cols":"6"}},[_c('router-link',{staticClass:"s--row-overlay s--row-link",attrs:{"to":{ name: 'Expertise', params: { id: item.id } }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#2865E1"}},[_vm._v("mdi-open-in-new")]),_vm._v(" Просмотреть ")],1)],1),_c('v-col',{attrs:{"lg":"2","cols":"6"}},[_c('v-tooltip',{attrs:{"top":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(item.countConnectivity < 3)?_c('div',{staticClass:"s--small-overlay s--overlay-open"},[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("mdi-lock-open")]),_vm._v(" Доступна ")],1):_vm._e(),(item.countConnectivity >= 3)?_c('div',{staticClass:"s--small-overlay s--overlay-close"},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-lock")]),_vm._v(" Не доступна ")],1):_vm._e()])]}}],null,true)},[_c('span',[_vm._v("Информация о возможности принятия данной заявки в работу")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"mt-1"},'div',attrs,false),on),[(item.isConnectivity > 0)?_c('div',{staticClass:"s--small-overlay s--overlay-open"},[(item.isResolutions > 0)?_c('span',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-lock")]),_vm._v(" Получено решение ")],1):_c('span',[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("mdi-lock-open")]),_vm._v(" В работе ")],1)]):_c('div',{staticClass:"s--small-overlay s--overlay-close"},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-lock")]),_vm._v(" Не рассмотрена ")],1)])]}}],null,true)},[_c('span',[_vm._v("Персональный статус по работе с заявкой")])])],1)],1)],1)],1)}),0),_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.totalPage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1):_c('div',[_c('v-alert',{attrs:{"border":"bottom","colored-border":"","type":"warning","elevation":"2"}},[_vm._v(" "+_vm._s(_vm.cabinet_status.alert)+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }