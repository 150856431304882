export default function (Vue) {
    Vue.confirm = {
		
        wrapper: null,

        _forkWrapper() {
            if ( document.querySelector(".givc-confirm-wrapper") == null ) {
                const node = document.createElement("div")
                node.className = "givc-confirm-wrapper";
                document.body.appendChild(node);
            }

            this.wrapper = document.querySelector(".givc-confirm-wrapper")
        },

        _close() {
            this.wrapper.remove()
        },

        q(text, cb = null, cb2 = null){
            this._forkWrapper()

            const node = document.createElement("div")
            node.className = "givc-confirm";
            node.innerText = text

            const nodeConfirmBtn = document.createElement("button")
            nodeConfirmBtn.className = "givc-confirm-btn";
            nodeConfirmBtn.innerText = 'Подтвердить'

            nodeConfirmBtn.onclick = () => {
                if ( typeof cb == "function" ) 
                    cb(true)
                
                this._close()
            }

            const nodeResetBtn = document.createElement("button")
            nodeResetBtn.className = "givc-reset-btn";
            nodeResetBtn.innerText = 'Отменить'

            nodeResetBtn.onclick = () => {
                if ( typeof cb2 == "function" ) cb2(false)
                this._close()
            }

            const nodeWrapperBtns = document.createElement("div")
            nodeWrapperBtns.className = "givc-wrapper-btns";

            nodeWrapperBtns.appendChild(nodeResetBtn)
            nodeWrapperBtns.appendChild(nodeConfirmBtn)
            node.appendChild(nodeWrapperBtns)

            this.wrapper.appendChild(node);
        }

	}
  
	Object.defineProperties(Vue.prototype, {
		$confirm: {
			get: function(){
				return Vue.confirm
			}
		}
	})
}
