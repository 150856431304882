<template>
	<div class="home">
		<div v-if="!(cabinet_status.id > 0)">
			<div class="s--plan">
				<h3 class="d-flex justify-space-between" style="color:rgb(40,101,225)">
				<span>Достижение персонального плана рассмотрения заявок:</span>
				<span>всего заявок {{ total }}; персональный план {{ params.plan }}</span>
				</h3>
				<v-progress-linear
				class="mt-2"
				:buffer-value="params.factOpen"
				stream
				rounded
				:value="params.factClose"
				height="20"
				:dark="(params.factClose > 50 ? true : false)"
				color="primary"
				>
				<template v-slot:default="{ value }">
					<strong>{{ Math.ceil(value) }}%</strong>
				</template>
				</v-progress-linear>
			</div>

			<v-checkbox
				v-model="isWork"
				dense
				hide-details
				label="Показать только заявки в работе"
			></v-checkbox>

			<div class="s--table">
				<div class="card-row" v-for="(item, i) in references" :key="i">
				<v-card elevation="0">
					<v-row class="small-bottom-offset">
						<!-- <v-col lg=2 cols=4>
						<div class="s--row-overlay" style="display:block">
							<div style="font-size:8px; margin-top:-5px">Номер заявки:</div>
							{{ item.registration_number }}
						</div>
						</v-col> -->
						<v-col lg=8 cols=12>
						<div class="s--row-overlay">
							{{ item.ter_name }}
							<v-divider v-if="item.isConnectivity > 0"></v-divider>
							<v-chip label color="lime lighten-4" v-if="item.isConnectivity > 0">
								к рассмотрению
							</v-chip>
						</div>
						</v-col>
						<v-col lg=2 cols=6>
						<router-link :to="{ name: 'Expertise', params: { id: item.id } }" class="s--row-overlay s--row-link">
							<v-icon color="#2865E1" class="mr-2">mdi-open-in-new</v-icon> Просмотреть
						</router-link>
						</v-col>
						<v-col lg=2 cols=6>
						<v-tooltip top color="secondary">
							<template v-slot:activator="{ on, attrs }">
								<div v-bind="attrs" v-on="on">
								<div class="s--small-overlay s--overlay-open" v-if="item.countConnectivity < 3">
									<v-icon color="teal">mdi-lock-open</v-icon> Доступна
								</div>
								<div class="s--small-overlay s--overlay-close" v-if="item.countConnectivity >= 3">
									<v-icon color="red">mdi-lock</v-icon> Не доступна
								</div>
								</div>
							</template>
							<span>Информация о возможности принятия данной заявки в работу</span>
						</v-tooltip>

						<v-tooltip bottom color="secondary">
							<template v-slot:activator="{ on, attrs }">
								<div class="mt-1" v-bind="attrs" v-on="on">
								<div class="s--small-overlay s--overlay-open" v-if="item.isConnectivity > 0">
									<span v-if="item.isResolutions > 0">
										<v-icon color="red">mdi-lock</v-icon> Получено решение
									</span>
									<span v-else>
										<v-icon color="teal">mdi-lock-open</v-icon> В работе
									</span>
								</div>
								<div class="s--small-overlay s--overlay-close" v-else>
									<v-icon color="red">mdi-lock</v-icon> Не рассмотрена
								</div>
								</div>
							</template>
							<span>Персональный статус по работе с заявкой</span>
						</v-tooltip>
						
						</v-col>
					</v-row>
				</v-card>
				</div>
			</div>

			<div class="text-center">
				<v-pagination v-model="page" :length="totalPage"></v-pagination>
			</div>
		</div>
		<div v-else>
			<v-alert
				border="bottom"
				colored-border
				type="warning"
				elevation="2"
			>
				{{ cabinet_status.alert }}
			</v-alert>
		</div>
	</div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      totalPage: 0,
      page: 1,
      
      params : {},
      cabinet_status : {},
      references: [],
		isWork: false,
    }
  },
  watch: {
    'page': function() {
      this.getExpertData()
    },
    'isWork': function() {
      this.getExpertData()
    }
  },
  methods: {
    getExpertData() {
      this.$http.get("expert", {
			params: {
				page: this.page,
				isWork: this.isWork ? 'true' : null
			}
		})
        .then((response) => {
					this.references = response.data.references
					this.total = response.data.total
					this.params = response.data.params
					this.cabinet_status = response.data.cabinet_status

					this.totalPage = response.data.totalPage
				})
        .catch(() => {
          this.$toast.error("Что-то пошло не так - сервер не ответил");
        });
    }
  },
  created() {
    this.getExpertData()
  }
}
</script>
