import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "StartPage", component: Home, meta : { forAuth: true } },
  { path: '/login', name: 'LoginPage', component: () => import(/* webpackChunkName: "LoginPage" */ '../views/LoginPage.vue'), meta : { forVisitors: true } },
  { path: "/expertise/:id", name: "Expertise", component: () => import(/* webpackChunkName: "Expertise" */ "../views/Expertise.vue"), meta : { forAuth: true }  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
